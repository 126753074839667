import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import styled from 'styled-components';

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPreviewPage(): void;
  onNextPage(): void;
  onPress(page: number): void;
}

const generateButtonStyle = (isActive: boolean) => {
  return {
    backgroundColor: isActive ? '#174f68' : undefined,
    color: isActive ? '#ffffff' : undefined,
    fontWeight: isActive ? 'bold' : undefined,
    border: isActive ? 'solid 1px white' : 'solid 1px transparent',
  };
};

const Pagination = ({ currentPage, onPress, totalPages, onNextPage, onPreviewPage }: PaginationProps) => {
  const containerRef = useRef<HTMLButtonElement>(null);

  const buttons = useMemo(() => {
    return Array.from({ length: totalPages }, (_, index) => {
      const pageNumber = index + 1;
      const isActive = index + 1 === currentPage;

      return (
        <button
          style={{ ...generateButtonStyle(isActive), width: 47, height: 47 }}
          key={index}
          ref={isActive ? containerRef : undefined}
          onClick={(e) => {
            e.currentTarget.focus();
            onPress(pageNumber);
          }}
        >
          {pageNumber}
        </button>
      );
    });
  }, [currentPage, onPress, totalPages]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [currentPage]);

  return (
    <Container>
      {totalPages > 1 && (
        <>
          <button disabled={currentPage === 1} onClick={onPreviewPage}>
            <FaArrowLeft size={15} />
          </button>
          <div
            style={{
              width: 150,
              display: 'flex',
              flexDirection: 'row',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 15,
                marginBottom: 10,
              }}
            >
              {buttons}
            </div>
          </div>

          <button disabled={currentPage === totalPages} onClick={onNextPage}>
            <FaArrowRight size={15} />
          </button>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  & button {
    border: none;
    height: 39px !important;
    width: 39px !important;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.2s;
    font-size: 14px;
    color: #174f68;

    :active {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    }
    :disabled {
      background-color: #f5f5f5;
      color: #cccccc;
      :active {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

export default Pagination;
