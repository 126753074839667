import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type ButtonProps = PropsWithChildren<{ transparent?: boolean, isLoading?: boolean}>;

const Button = styled.button<ButtonProps>`
  border: none;
  background: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  background: ${props => props.transparent ? 'transparent' : '#174F68'}!important;
  padding: 1.5rem;
  color: ${props => props.transparent ? '#174F68' : '#fff'}!important;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 4px!important;
  transition: transform 0.3s;
  backface-visibility: hidden;
  will-change: transform;
  cursor: pointer;
  border: ${props => props.transparent ? 'solid 1px #174F68' : 'none'}!important;
  opacity: ${props => props.isLoading ? '0.5' : '1'};
  span {
    margin-left: 1.5rem;
  }

  &:hover {
    transform: scale(1.025);
  }

  :disabled {
    background-color: #bbb;
    color: #fff;
    border: none;
  }
`;

export default Button;
