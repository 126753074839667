/**
 * This method create a friendly string name
 * @param names fullname string
 * @param separator separator  ``|`` default
 * @returns
 */
export const buildName = (names?: string, joinWith?: string, separator = '|') => {
  if (!names) return '';
  var arrNames = names.split(separator).filter((item) => item !== '');
  var name = arrNames.join(', ');

  return (
    name.substring(0, name.lastIndexOf(',')) +
    (arrNames.length > 1 ? (joinWith ? joinWith : ' e ') : '') +
    name.substring(name.lastIndexOf(','), name.length).replace(', ', '')
  );
};

export const fontAdjusts = (texto: string, tamanhoFonteBase = 14) => {
  const tamanhoTexto = texto.length;

  const fatorAjuste = 0.5; // Ajuste esse valor conforme necessário

  const tamanhoFonteCalculado = tamanhoFonteBase - tamanhoTexto * fatorAjuste;

  // Aplicar limite mínimo para o tamanho da fonte (por exemplo, 10 pixels)
  return Math.max(tamanhoFonteCalculado, 10);
};
